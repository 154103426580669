import React from "react";
import {SvgIcon} from "@material-ui/core";

export function CPlusPlusIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
                <g id="surface1">
                    <path style={{fillRule: "evenodd", fill: "#00549D"}}
                          d="M 22.902344 3.285156 C 23.582031 2.90625 24.417969 2.90625 25.097656 3.285156 C 28.449219 5.167969 38.546875 10.835938 41.902344 12.71875 C 42.582031 13.101563 43 13.804688 43 14.566406 C 43 18.332031 43 29.667969 43 33.433594 C 43 34.195313 42.582031 34.898438 41.902344 35.28125 C 38.546875 37.164063 28.453125 42.832031 25.097656 44.714844 C 24.417969 45.09375 23.582031 45.09375 22.902344 44.714844 C 19.546875 42.832031 9.453125 37.164063 6.097656 35.28125 C 5.417969 34.898438 5 34.195313 5 33.433594 C 5 29.667969 5 18.332031 5 14.566406 C 5 13.804688 5.417969 13.101563 6.097656 12.71875 C 9.449219 10.835938 19.550781 5.167969 22.902344 3.285156 Z "/>
                    <path style={{fillRule: "evenodd", fill: "#0086D4"}}
                          d="M 5.304688 34.402344 C 5.039063 34.046875 5 33.710938 5 33.253906 C 5 29.511719 5 18.242188 5 14.496094 C 5 13.738281 5.417969 13.039063 6.09375 12.660156 C 9.4375 10.789063 19.5 5.152344 22.84375 3.28125 C 23.519531 2.902344 24.4375 2.910156 25.113281 3.289063 C 28.457031 5.160156 38.484375 10.746094 41.828125 12.617188 C 42.097656 12.769531 42.304688 12.953125 42.488281 13.195313 Z "/>
                    <path style={{fillRule: "evenodd", fill: "#FFFFFF"}}
                          d="M 24 10 C 31.726563 10 38 16.273438 38 24 C 38 31.726563 31.726563 38 24 38 C 16.273438 38 10 31.726563 10 24 C 10 16.273438 16.273438 10 24 10 Z M 24 17 C 27.863281 17 31 20.136719 31 24 C 31 27.863281 27.863281 31 24 31 C 20.136719 31 17 27.863281 17 24 C 17 20.136719 20.136719 17 24 17 Z "/>
                    <path style={{fillRule: "evenodd", fill: "#0075C0"}}
                          d="M 42.484375 13.203125 C 43 13.6875 42.992188 14.414063 42.992188 14.988281 C 42.992188 18.785156 42.960938 29.578125 43 33.371094 C 43.003906 33.769531 42.871094 34.1875 42.675781 34.5 L 23.59375 24 Z "/>
                    <path style={{fillRule: "evenodd", fill: "#FFFFFF"}} d="M 31 21 L 33 21 L 33 27 L 31 27 Z "/>
                    <path style={{fillRule: "evenodd", fill: "#FFFFFF"}} d="M 38 21 L 40 21 L 40 27 L 38 27 Z "/>
                    <path style={{fillRule: "evenodd", fill: "#FFFFFF"}} d="M 29 23 L 35 23 L 35 25 L 29 25 Z "/>
                    <path style={{fillRule: "evenodd", fill: "#FFFFFF"}} d="M 36 23 L 42 23 L 42 25 L 36 25 Z "/>
                </g>
            </svg>
        </SvgIcon>
    );
}