export const UPDATE_WEBP_SUPPORT_LOSSY = "UPDATE_WEBP_SUPPORT_LOSSY";
export const UPDATE_WEBP_SUPPORT_LOSSLESS = "UPDATE_WEBP_SUPPORT_LOSSLESS";
export const UPDATE_APP_BAR = "UPDATE_APP_BAR";
export const UPDATE_ABOUT = "UPDATE_ABOUT";
export const UPDATE_SKILLS = "UPDATE_SKILLS";
export const UPDATE_PORTFOLIO = "UPDATE_PORTFOLIO";
export const UPDATE_EDUCATION = "UPDATE_EDUCATION";
export const UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const SET_ACTION_MESSAGE = "SET_ACTION_MESSAGE";
export const SET_TOUCH_SCREEN_STATUS = "SET_TOUCH_SCREEN_STATUS";
export const SET_CONTACT_FORM_STATUS = "SET_CONTACT_FORM_STATUS";