import React from "react";
import {SvgIcon} from "@material-ui/core";

export function CSharpIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48"
                 style={{enableBackground: "new 0 0 48 48"}} xmlSpace="preserve" width="24px" height="24px">
                <path style={{fill: "#00C853"}}
                      d="M22.903,3.286c0.679-0.381,1.515-0.381,2.193,0c3.355,1.883,13.451,7.551,16.807,9.434  C42.582,13.1,43,13.804,43,14.566c0,3.766,0,15.101,0,18.867c0,0.762-0.418,1.466-1.097,1.847  c-3.355,1.883-13.451,7.551-16.807,9.434c-0.679,0.381-1.515,0.381-2.193,0c-3.355-1.883-13.451-7.551-16.807-9.434  C5.418,34.899,5,34.196,5,33.434c0-3.766,0-15.101,0-18.867c0-0.762,0.418-1.466,1.097-1.847  C9.451,10.837,19.549,5.169,22.903,3.286z"/>
                <path style={{fill: "#69F0AE"}}
                      d="M5.304,34.404C5.038,34.048,5,33.71,5,33.255c0-3.744,0-15.014,0-18.759  c0-0.758,0.417-1.458,1.094-1.836c3.343-1.872,13.405-7.507,16.748-9.38c0.677-0.379,1.594-0.371,2.271,0.008  c3.343,1.872,13.371,7.459,16.714,9.331c0.27,0.152,0.476,0.335,0.66,0.576L5.304,34.404z"/>
                <path style={{fill: "#FFFFFF"}}
                      d="M24,10c-7.73,0-14,6.27-14,14s6.27,14,14,14s14-6.27,14-14S31.73,10,24,10z M24,31  c-3.86,0-7-3.14-7-7s3.14-7,7-7s7,3.14,7,7S27.86,31,24,31z"/>
                <path style={{fill: "#00E676"}}
                      d="M42.485,13.205c0.516,0.483,0.506,1.211,0.506,1.784c0,3.795-0.032,14.589,0.009,18.384  c0.004,0.396-0.127,0.813-0.323,1.127L23.593,24L42.485,13.205z"/>
                <rect x="34" y="20" style={{fill: "#FFFFFF"}} width="1" height="8"/>
                <rect x="37" y="20" style={{fill: "#FFFFFF"}} width="1" height="8"/>
                <rect x="32" y="25" style={{fill: "#FFFFFF"}} width="8" height="1"/>
                <rect x="32" y="22" style={{fill: "#FFFFFF"}} width="8" height="1"/>
            </svg>
        </SvgIcon>
    );
}