import React from "react";
import {SvgIcon} from "@material-ui/core";

export function BitriseIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg height="24px" viewBox="0 0 64 64" width="24px" xmlns="http://www.w3.org/2000/svg">
                <g fill="#760FC3" fillRule="evenodd" strokeWidth=".751727"
                   transform="matrix(1.8221645 0 0 1.8221645 -18.221626 -22.664935)">
                    <path
                        d="m27.561146 38.785053a3.9052199 3.9052199 0 0 1 -3.90522-3.90522h7.811191a3.9052199 3.9052199 0 0 1 -3.90522 3.90522zm-8.728299-8.72905a2.4115391 2.4115391 0 0 0 -2.41229 2.413043h4.823078a2.4115391 2.4115391 0 0 0 -2.411539-2.411539m17.475389 0a2.4115391 2.4115391 0 0 0 -2.410787 2.410787h4.822326a2.4115391 2.4115391 0 0 0 -2.411539-2.410787m-8.746339-11.490142a1.4940567 1.4940567 0 1 0 0-2.988113 1.4940567 1.4940567 0 0 0 0 2.988113"/>
                    <path
                        d="m42.02587 40.801184c-.482554.543199-1.164692.867587-1.890593.899065-3.014424.140573-7.642805.308208-12.57338.308208-4.931327 0-9.558956-.167635-12.57338-.308208-.725993-.03176-1.408116-.356409-1.890592-.899817-.484235-.538688-.729425-1.25066-.679561-1.973282.159366-2.411539.37436-4.546443.638968-6.345325.393153-2.670133.984761-5.380107 1.41099-7.184251.268584-1.158993 1.268921-2.002301 2.456643-2.071007 2.186021-.136063 5.805585-.298436 10.636932-.298436 4.830596 0 8.450911.162373 10.636932.298436 1.187437.06903 2.187386.912268 2.455891 2.071007.427733 1.804144 1.01859 4.515622 1.410991 7.184251.26536 1.798882.480354 3.933786.638968 6.344573.05031.723001-.19461 1.435516-.678809 1.974786m2.425822-8.671167c-.405933-2.752823-1.011824-5.537218-1.450081-7.387969-.509395-2.194835-2.403812-3.791777-4.653188-3.92251-2.219849-.137566-5.893537-.302946-10.787277-.302946-4.892237 0-8.565926.16538-10.785774.302946-2.249473.130489-4.144017 1.727538-4.653188 3.92251-.438257 1.849999-1.0449 4.633643-1.450833 7.387969-.27438 1.862779-.496139 4.062331-.659264 6.538519-.094706 1.368941.369647 2.71779 1.286956 3.738336.912648 1.028153 2.203228 1.64225 3.576715 1.701909 3.039983.142828 7.708205.311967 12.686139.311967 4.976431 0 9.645405-.16989 12.686139-.311967 1.373243-.05977 2.663548-.673869 3.575964-1.701909.917309-1.020546 1.381662-2.369395 1.286956-3.738336-.163125-2.476188-.384884-4.67574-.659264-6.538519"/>
                </g>
            </svg>
        </SvgIcon>
    );
}